// Action types constants

export const categoriesActionTypes = {
  CATEGORIES_LOADING: 'CATEGORIES_LOADING',
  CATEGORIES_LOADED: 'CATEGORIES_LOADED',
  ALL_CATEGORIES: 'ALL_CATEGORIES',
};

export const categoriesLoadingStart = () => ({
  type: categoriesActionTypes.CATEGORIES_LOADING,
});

export const categoriesLoadingSuccess = () => ({
  type: categoriesActionTypes.CATEGORIES_LOADED,
});

export const saveCategories = payload => ({
  type: categoriesActionTypes.ALL_CATEGORIES,
  payload,
});
