import Config from '../../../config/index';
import {postRequest} from './axios-requests';

export default class CheckoutService {

  static async getCheckoutOptions() {
    try {
      return await postRequest(Config.api.checkoutOptions);
    } catch (err) {
      throw err;
    }
  }

  static async getDeliveryRates(body) {
    try {
      return await postRequest(Config.api.checkoutDeliveryRates, body);
    } catch (err) {
      throw err;
    }
  }

  static async getDeliveryDistance(body) {
    try {
      return await postRequest(Config.api.checkoutDeliveryDistance, body);
    } catch (err) {
      throw err;
    }
  }

  static async calculateCheckout(body) {
    try {
      return await postRequest(Config.api.checkoutCalculate, body);
    } catch (err) {
      throw err;
    }
  }

  static async getZipCodeList() {
    try {
      return await postRequest(Config.api.zipCodeList);
    } catch (err) {
      throw err;
    }
  }

}
