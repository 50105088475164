import {checkoutRatesActionTypes} from '../actions/checkout-rates';

const initialState = {
  isRatesLoading: false,
  isRatesLoaded: false,
  rates: [],
};

const checkoutRatesReducer = (state = initialState, action) => {

  switch (action.type) {
    case checkoutRatesActionTypes.CHECKOUT_RATES_LOADING:

      return {...state, isRatesLoading: true};

    case checkoutRatesActionTypes.CHECKOUT_RATES_LOADED:

      return {...state, isRatesLoading: false, isRatesLoaded: true};

    case checkoutRatesActionTypes.CHECKOUT_RATES:

      return {...state, rates: action.payload};

    case checkoutRatesActionTypes.REMOVE_CHECKOUT_RATES:

      return initialState;

    default:
      return state;
  }
};

export default checkoutRatesReducer;
