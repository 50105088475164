const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  openApiKey: process.env.REACT_APP_OPEN_API_KEY,
  salesOutletId: process.env.REACT_APP_SALES_OUTLET_ID,
  applicationUuid: process.env.REACT_APP_UUID,
  api: {
    signin: 'auth/signin',
    signup: 'auth/signup',
    forgotPassword: 'auth/forgot-password',
    regenerateToken: 'auth/regenerate-token',
    verifyCustomer: 'auth/verify-customer',
    config: 'sales-outlet/config',
    theme: 'sales-outlet/theme',
    menu: 'menu/list',
    filters: 'category/filters',
    categories: 'category/list',
    categoryView: 'category/view',
    products: 'product/list',
    search: 'product/search',
    product: 'product/view',
    pageView: 'page/view',
    cartCalculate: 'cart/calculate',
    cartValidate: 'cart/validate',
    checkoutOptions: 'checkout/options',
    checkoutDeliveryRates: 'checkout/delivery-rates',
    checkoutDeliveryDistance: 'checkout/delivery-distance',
    checkoutCalculate: 'checkout/calculate',
    paymentCreate: 'checkout/payment-create',
    paymentComplete: 'checkout/payment-complete',
    paymentCompleteLog: 'checkout/paymentLog',
    giftCardView: 'gift-card/view',
    giftCards: 'gift-card/list',
    giftCardsCustomAmount: 'gift-card/get-product',
    giftCardDownload: 'gift-card/download',
    cardOnFiles: 'card-on-file/list',
    setAccount: 'card-on-file/set-account',
    createCardOnFile: 'card-on-file/create',
    deleteCardOnFile: 'card-on-file/delete',
    setPrimaryCardOnFile: 'card-on-file/set-primary',
    customerView: 'customer/view',
    customerUpdate: 'customer/update',
    customerUpdatePassword: 'customer/update-password',
    customerQuotesList: 'customer/quotes-list',
    purchasesList: 'customer/purchases-list',
    downloadReceipt: 'customer/download-receipt',
    uploadAvatar: 'customer/upload-avatar',
    removeAvatar: 'customer/remove-avatar',
    setPassword: 'customer/set-password',
    zipCodeList: '/checkout/zip-code-list',
  },
  signInQueryParam: 'sign-in',
  signUpQueryParam: 'sign-up',
  forgotPasswordQueryParam: 'forgot-password',
  selectOutletQueryParam: 'select-outlet',
  setPasswordQueryParam: 'set-password',
  sideNavigationQueryParam: 'side-navigation',
  catalogFiltersQueryParam: 'catalog-filters',
  guestId: 1,
  bannerSlider: {
    minSlide: 1,
    maxSlide: 2,
    sliderInterval: 3000,
  },
  deliveryTypes: {
    1: 'Pick Up',
    2: 'Ship It',
    5: 'Local Delivery',
  },
  paymentCompleteStatus: {
    completed: 1,
    canceled: 2,
  },
};

export default config;
