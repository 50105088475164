// Action types constants

export const shipDeliveryDataActionTypes = {
  SHIP_DELIVERY_DATA: 'SHIP_DELIVERY_DATA',
  REMOVE_SHIP_DELIVERY_DATA: 'REMOVE_SHIP_DELIVERY_DATA',
};

export const updateShipDeliveryData = payload => ({
  type: shipDeliveryDataActionTypes.SHIP_DELIVERY_DATA,
  payload,
});

export const removeShipDeliveryData = () => ({
  type: shipDeliveryDataActionTypes.REMOVE_SHIP_DELIVERY_DATA,
});
