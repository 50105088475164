// Action types constants

export const paymentDataActionTypes = {
  PAYMENT_DATA: 'PAYMENT_DATA',
  REMOVE_PAYMENT_DATA: 'REMOVE_PAYMENT_DATA',
};

export const updatePaymentData = payload => ({
  type: paymentDataActionTypes.PAYMENT_DATA,
  payload,
});

export const removePaymentData = () => ({
  type: paymentDataActionTypes.REMOVE_PAYMENT_DATA,
});
