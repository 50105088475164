import {shipDeliveryDataActionTypes} from '../actions/ship-delivery-data';

const initialState = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  region: {label: '', value: ''},
  carrier: {label: '', value: ''},
  service: {label: '', value: ''},
  rate: '',
  notes: '',
};

const ShipDeliveryDataReducer = (state = initialState, action) => {

  switch (action.type) {
    case shipDeliveryDataActionTypes.SHIP_DELIVERY_DATA:

      return {...state, ...action.payload};

    case shipDeliveryDataActionTypes.REMOVE_SHIP_DELIVERY_DATA:

      return initialState;

    default:
      return state;
  }
};

export default ShipDeliveryDataReducer;
