import {Component} from 'react';
import {withRouter} from 'react-router-dom';

class PaymentIframeTransition extends Component {

  constructor(props) {
    super();

    window.parent.postMessage(props.location.search, '*');
    if (props.paymentMethod === 2) {
      props.history.push({
        pathname: '/complete-payment',
        state: props.location.search,
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(PaymentIframeTransition);
