import {paymentDataActionTypes} from '../actions/payment-data';

const initialState = {
  firstNameShipping: '',
  lastNameShipping: '',
  phoneShipping: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  region: {label: '', value: ''},
  country: '',
};

const PaymentDataReducer = (state = initialState, action) => {

  switch (action.type) {
    case paymentDataActionTypes.PAYMENT_DATA:

      return {...state, ...action.payload};

    case paymentDataActionTypes.REMOVE_PAYMENT_DATA:

      return initialState;

    default:
      return state;
  }
};

export default PaymentDataReducer;
