// Action types constants

export const localDeliveryDistanceActionTypes = {
  DISTANCE_LOADING: 'DISTANCE_LOADING',
  DISTANCE_LOADED: 'DISTANCE_LOADED',
  DISTANCE: 'DISTANCE',
  DISTANCE_REMOVE: 'DISTANCE_REMOVE',
};

export const localDeliveryDistanceLoadingStart = () => ({
  type: localDeliveryDistanceActionTypes.DISTANCE_LOADING,
});

export const localDeliveryDistanceLoadingSuccess = () => ({
  type: localDeliveryDistanceActionTypes.DISTANCE_LOADED,
});

export const saveLocalDeliveryDistance = payload => ({
  type: localDeliveryDistanceActionTypes.DISTANCE,
  payload,
});

export const removeLocalDeliveryDistance = () => ({
  type: localDeliveryDistanceActionTypes.DISTANCE_REMOVE,
});
