// Action types constants

export const userActionTypes = {
  USER_LOADING: 'USER_LOADING',
  USER_LOADED: 'USER_LOADED',
  SAVE_USER: 'SAVE_USER',
  UPDATE_AVATAR_IMAGES: 'UPDATE_AVATAR_IMAGES',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

export const userLoadingStart = () => ({
  type: userActionTypes.USER_LOADING,
});

export const userLoadingSuccess = () => ({
  type: userActionTypes.USER_LOADED,
});

export const saveUser = payload => ({
  type: userActionTypes.SAVE_USER,
  payload,
});

export const updateAvatarImages = payload => ({
  type: userActionTypes.UPDATE_AVATAR_IMAGES,
  payload,
});

export const setInitialState = () => ({
  type: userActionTypes.SET_INITIAL_STATE,
});
