// Action types constants

export const pickUpDeliveryDataActionTypes = {
  PICK_UP_DELIVERY_DATA: 'PICK_UP_DELIVERY_DATA',
  REMOVE_PICK_UP_DELIVERY_DATA: 'REMOVE_PICK_UP_DELIVERY_DATA',
};

export const updatePickUpDeliveryData = payload => ({
  type: pickUpDeliveryDataActionTypes.PICK_UP_DELIVERY_DATA,
  payload,
});

export const removePickUpDeliveryData = () => ({
  type: pickUpDeliveryDataActionTypes.REMOVE_PICK_UP_DELIVERY_DATA,
});
