// Action types constants

export const giftCardActionTypes = {
  UPDATE_GIFT_CARD: 'UPDATE_GIFT_CARD',
  GIFT_CARD_LOADING: 'GIFT_CARD_LOADING',
  GIFT_CARD_LOADED: 'GIFT_CARD_LOADED',
  SET_GIFT_CARD_INITIAL_STATE: 'SET_GIFT_CARD_INITIAL_STATE',
};

export const giftCardLoadingStart = () => ({
  type: giftCardActionTypes.GIFT_CARD_LOADING,
});

export const giftCardLoadingSuccess = () => ({
  type: giftCardActionTypes.GIFT_CARD_LOADED,
});

export const updateGiftCard = payload => ({
  type: giftCardActionTypes.UPDATE_GIFT_CARD,
  payload,
});

export const setGiftCardInitialState = () => ({
  type: giftCardActionTypes.SET_GIFT_CARD_INITIAL_STATE,
});
