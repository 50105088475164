import {salesOutletIdActionTypes} from '../actions/sales-outlet';

const initialState = {
  salesOutletId: '',
  locationId: '',
};

const salesOutletReducer = (state = initialState, action) => {

  switch (action.type) {
    case salesOutletIdActionTypes.UPDATE_SALES_OUTLET_ID:

      return {...state, salesOutletId: action.payload};

    case salesOutletIdActionTypes.UPDATE_LOCATION_ID:

      return {...state, locationId: action.payload};

    default:
      return state;
  }
};

export default salesOutletReducer;
