import {cardOnFilesAccountActionTypes} from '../actions/card-on-files-account';

const initialState = {
  isCardOnFilesAccountLoading: false,
  isCardOnFilesAccountLoaded: false,
};

const cardOnFilesAccountReducer = (state = initialState, action) => {

  switch (action.type) {
    case cardOnFilesAccountActionTypes.CARD_ON_FILES_ACCOUNT_LOADING:

      return {...state, isCardOnFilesAccountLoading: true};

    case cardOnFilesAccountActionTypes.CARD_ON_FILES_ACCOUNT_LOADED:

      return {...state, isCardOnFilesAccountLoading: false, isCardOnFilesAccountLoaded: true};

    default:
      return state;
  }
};

export default cardOnFilesAccountReducer;
