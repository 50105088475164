import {giftCardsActionTypes} from '../actions/gift-cards';

const initialState = {
  isGiftCardsLoading: false,
  isGiftCardsLoaded: false,
  giftCards: [],
};

const giftCardsReducer = (state = initialState, action) => {

  switch (action.type) {
    case giftCardsActionTypes.GIFT_CARDS_LOADING:

      return {...state, isGiftCardsLoading: true};

    case giftCardsActionTypes.GIFT_CARDS_LOADED:

      return {...state, isGiftCardsLoading: false, isGiftCardLoaded: true};

    case giftCardsActionTypes.ALL_GIFT_CARDS:

      return {...state, giftCards: action.payload};

    default:
      return state;
  }
};

export default giftCardsReducer;
