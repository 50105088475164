// Action types constants

export const customerHasPasswordActionTypes = {
  CUSTOMER_HAS_PASSWORD: 'CUSTOMER_HAS_PASSWORD',
};

export const isCustomerHasPassword = payload => ({
  type: customerHasPasswordActionTypes.CUSTOMER_HAS_PASSWORD,
  payload,
});
