// Action types constants

export const applicationUuidActionTypes = {
  APPLICATION_UUID: 'APPLICATION_UUID',
};

export const saveApplicationUuid = payload => ({
  type: applicationUuidActionTypes.APPLICATION_UUID,
  payload,
});
