import Config from '../../../config/index';
import {postRequest} from './axios-requests';

export default class UserService {

  static async getCustomer() {
    try {
      return await postRequest(Config.api.customerView, null);
    } catch (err) {
      throw err;
    }
  }

  static async customerUpdate(body) {
    try {
      return await postRequest(Config.api.customerUpdate, body);
    } catch (err) {
      throw err;
    }
  }

  static async getPurchasesList(body) {
    try {
      return await postRequest(Config.api.purchasesList, body);
    } catch (err) {
      throw err;
    }
  }

  static async downloadReceipt(body) {
    try {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      return await postRequest(Config.api.downloadReceipt, body, headers, 'blob');
    } catch (err) {
      throw err;
    }
  }

  static async uploadAvatar(body) {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      return await postRequest(Config.api.uploadAvatar, body, headers);
    } catch (err) {
      throw err;
    }
  }

  static async removeAvatar() {
    try {
      return await postRequest(Config.api.removeAvatar);
    } catch (err) {
      throw err;
    }
  }

  static async setPassword(body) {
    try {
      return await postRequest(Config.api.setPassword, body);
    } catch (err) {
      throw err;
    }
  }
}
