import {checkoutOptionsActionTypes} from '../actions/checkout-options';

const initialState = {
  isCheckoutOptionsLoading: false,
  isCheckoutOptionsLoaded: false,
  checkoutOptions: {},
};

const checkoutOptionsReducer = (state = initialState, action) => {

  switch (action.type) {
    case checkoutOptionsActionTypes.CHECKOUT_OPTIONS_LOADING:

      return {...state, isCheckoutOptionsLoading: true};

    case checkoutOptionsActionTypes.CHECKOUT_OPTIONS_LOADED:

      return {...state, isCheckoutOptionsLoading: false, isCheckoutOptionsLoaded: true};

    case checkoutOptionsActionTypes.CHECKOUT_OPTIONS:

      return {...state, checkoutOptions: action.payload};

    default:
      return state;
  }
};

export default checkoutOptionsReducer;
