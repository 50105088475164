import {checkoutCalculationsActionTypes} from '../actions/checkout-calculations';

const initialState = {
  isCalculationsLoading: false,
  isCalculationsLoaded: false,
  calculations: {},
};

const checkoutCalculationsReducer = (state = initialState, action) => {

  switch (action.type) {
    case checkoutCalculationsActionTypes.CHECKOUT_CALCULATIONS_LOADING:

      return {...state, isCalculationsLoading: true};

    case checkoutCalculationsActionTypes.CHECKOUT_CALCULATIONS_LOADED:

      return {...state, isCalculationsLoading: false, isCalculationsLoaded: true};

    case checkoutCalculationsActionTypes.CHECKOUT_CALCULATIONS:

      return {...state, calculations: action.payload};

    default:
      return state;
  }
};

export default checkoutCalculationsReducer;
