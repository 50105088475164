// Action types constants

export const cartActionTypes = {
  UPDATE_CART_PRODUCTS: 'UPDATE_CART_PRODUCTS',
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  UPDATE_PRODUCT_QUANTITY: 'UPDATE_PRODUCT_QUANTITY',
  UPDATE_CALCULATIONS: 'UPDATE_CALCULATIONS',
  CLEAR_CART: 'CLEAR_CART',
  ADD_ENTERED_COUPON: 'ADD_ENTERED_COUPON',
  ADD_COUPON_TO_COUPONS_LIST: 'ADD_COUPON_TO_COUPONS_LIST',
  REMOVE_COUPON: 'REMOVE_COUPON',
  DELETE_ENTERED_COUPON: 'DELETE_ENTERED_COUPON',
  START_CALCULATION: 'START_CALCULATION',
  STOP_CALCULATION: 'STOP_CALCULATION',
};

export const updateCartProducts = payload => ({
  type: cartActionTypes.UPDATE_CART_PRODUCTS,
  payload,
});

export const addToCart = payload => ({
  type: cartActionTypes.ADD_TO_CART,
  payload,
});

export const removeCartItem = (id, modifiers) => ({
  type: cartActionTypes.REMOVE_CART_ITEM,
  id,
  modifiers,
});

export const updateProductQuantity = (id, quantity, modifiers) => ({
  type: cartActionTypes.UPDATE_PRODUCT_QUANTITY,
  id,
  quantity,
  modifiers,
});

export const updateCalculations = payload => ({
  type: cartActionTypes.UPDATE_CALCULATIONS,
  payload,
});

export const clearCart = () => ({
  type: cartActionTypes.CLEAR_CART,
});

export const addEnteredCoupon = payload => ({
  type: cartActionTypes.ADD_ENTERED_COUPON,
  payload,
});

export const addCouponToCouponsList = payload => ({
  type: cartActionTypes.ADD_COUPON_TO_COUPONS_LIST,
  payload,
});

export const removeCoupon = payload => ({
  type: cartActionTypes.REMOVE_COUPON,
  payload,
});

export const deleteEnteredCoupon = () => ({
  type: cartActionTypes.DELETE_ENTERED_COUPON,
});

export const startCalculation = () => ({
  type: cartActionTypes.START_CALCULATION,
});

export const stopCalculation = () => ({
  type: cartActionTypes.STOP_CALCULATION,
});
