// Action types constants

export const themeActionTypes = {
  THEME_LOADING: 'THEME_LOADING',
  THEME_LOADED: 'THEME_LOADED',
  UPDATE_THEME: 'UPDATE_THEME',
};

export const themeLoadingStart = () => ({
  type: themeActionTypes.THEME_LOADING,
});

export const themeLoadingSuccess = () => ({
  type: themeActionTypes.THEME_LOADED,
});

export const saveTheme = payload => ({
  type: themeActionTypes.UPDATE_THEME,
  payload,
});
