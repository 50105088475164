// Action types constants

export const activeDeliveryTypeActionTypes = {
  ACTIVE_DELIVERY_TYPE: 'ACTIVE_DELIVERY_TYPE',
};

export const updateActiveDeliveryType = payload => ({
  type: activeDeliveryTypeActionTypes.ACTIVE_DELIVERY_TYPE,
  payload,
});
