import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';

import AuthService from '../../services/api/auth';
import UserService from '../../services/api/user';
import Spinner from '../../../themes/instacart/shared/components/spinner/spinner';
import {updateToken} from '../../../store/actions/token';
import {isCustomerHasPassword} from '../../../store/actions/customer-has-password';
import {saveUser, userLoadingStart, userLoadingSuccess} from '../../../store/actions/user';
import DefaultContext from '../../../contexts/default';

class RegistrationLinkContainer extends Component {

  async componentDidMount() {
    const token = await this.verifyCustomer();
    const customer = await this.getCustomer();

    if (token && customer) {
      this.props.isCustomerHasPassword(false);
      this.props.history.push({pathname: '/', hash: 'set-password'});
    }
  }

  verifyCustomer = async () => {
    try {
      const {match, salesOutlet} = this.props;
      const {customerId, accessToken} = match.params;
      const {salesOutletId} = salesOutlet;
      const token = await AuthService.verifyCustomer({
        customerId,
        accessToken,
        customSalesOutletId: salesOutletId,
      });
      this.props.updateToken(token);

      return token;
    } catch (err) {
      this.props.history.push({pathname: '/', state: {errorMsg: err.message}});
    }
  };

  getCustomer = async () => {
    try {
      this.props.userLoadingStart();

      const customer = await UserService.getCustomer();

      this.props.saveUser(customer);
      this.props.userLoadingSuccess();
      return customer;
    } catch (err) {}
  };

  render() {
    return (
      <div>
        <Spinner active />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    salesOutlet: state.salesOutlet,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateToken,
    saveUser,
    userLoadingStart,
    userLoadingSuccess,
    isCustomerHasPassword,
  }, dispatch);
};

RegistrationLinkContainer.contextType = DefaultContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationLinkContainer));
