import {productActionTypes} from '../actions/product';

const initialState = {
  isProductLoading: false,
  isProductLoaded: false,
  product: {},
};

const productReducer = (state = initialState, action) => {

  switch (action.type) {
    case productActionTypes.PRODUCT_LOADING:

      return {...state, isProductLoading: true};

    case productActionTypes.PRODUCT_LOADED:

      return {...state, isProductLoading: false, isProductLoaded: true};

    case productActionTypes.SAVE_PRODUCT:

      return {...state, product: action.payload};

    default:
      return state;
  }
};

export default productReducer;
