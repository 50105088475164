// Action types constants

export const configActionTypes = {
  CONFIG_LOADING: 'CONFIG_LOADING',
  CONFIG_LOADED: 'CONFIG_LOADED',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
};

export const configLoadingStart = () => ({
  type: configActionTypes.CONFIG_LOADING,
});

export const configLoadingSuccess = () => ({
  type: configActionTypes.CONFIG_LOADED,
});

export const saveConfig = payload => ({
  type: configActionTypes.UPDATE_CONFIG,
  payload,
});
