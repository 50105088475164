import {tokenActionTypes} from '../actions/token';

const tokenInitialState = {
  isTokenLoading: false,
  isTokenLoaded: false,
  accessToken: '',
  customerId: 1,
  expiresIn: null,
};

const tokenReducer = (state = tokenInitialState, action) => {

  switch (action.type) {
    case tokenActionTypes.TOKEN_LOADING:

      return {...state, isTokenLoading: true};

    case tokenActionTypes.TOKEN_LOADED:

      return {...state, isTokenLoading: false, isTokenLoaded: true};

    case tokenActionTypes.UPDATE_TOKEN:
      const {accessToken, customerId, expiresIn} = action.payload;

      return {...state, accessToken, customerId, expiresIn};

    case tokenActionTypes.DELETE_TOKEN:

      return tokenInitialState;

    default:
      return state;
  }
};

export default tokenReducer;
