import errorTemplate from '../error-template';

export default response => {
  const {status, data: {resource}} = response;
  let message = '';

  if (resource && resource.products[0]) {
    [message] = resource.products;
  }

  throw errorTemplate(status, message);
};
