// Action types constants

export const checkoutRatesActionTypes = {
  CHECKOUT_RATES_LOADING: 'CHECKOUT_RATES_LOADING',
  CHECKOUT_RATES_LOADED: 'CHECKOUT_RATES_LOADED',
  CHECKOUT_RATES: 'CHECKOUT_RATES',
  REMOVE_CHECKOUT_RATES: 'REMOVE_CHECKOUT_RATES',
};

export const checkoutRatesLoadingStart = () => ({
  type: checkoutRatesActionTypes.CHECKOUT_RATES_LOADING,
});

export const checkoutRatesLoadingSuccess = () => ({
  type: checkoutRatesActionTypes.CHECKOUT_RATES_LOADED,
});

export const saveCheckoutRates = payload => ({
  type: checkoutRatesActionTypes.CHECKOUT_RATES,
  payload,
});

export const removeCheckoutRates = () => ({
  type: checkoutRatesActionTypes.REMOVE_CHECKOUT_RATES,
});
