// Action types constants

export const searchValueActionTypes = {
  UPDATE_SEARCH_VALUE: 'UPDATE_SEARCH_VALUE',
};

export const updateSearchValue = payload => ({
  type: searchValueActionTypes.UPDATE_SEARCH_VALUE,
  payload,
});
