import {cardOnFilesActionTypes} from '../actions/card-on-files';

const initialState = {
  isCardOnFilesLoading: false,
  isCardOnFilesLoaded: false,
  cardOnFiles: [],
  cardOnFileId: '',
  primaryId: null,
};

const cardOnFilesReducer = (state = initialState, action) => {

  switch (action.type) {
    case cardOnFilesActionTypes.CARD_ON_FILES_LOADING:

      return {...state, isCardOnFilesLoading: true};

    case cardOnFilesActionTypes.CARD_ON_FILES_LOADED:

      return {...state, isCardOnFilesLoading: false, isCardOnFilesLoaded: true};

    case cardOnFilesActionTypes.ALL_CARD_ON_FILES:
      const {list, primaryId} = action.payload;
      return {...state, cardOnFiles: list, primaryId};

    case cardOnFilesActionTypes.CARD_ON_FILE_ID:

      return {...state, cardOnFileId: action.payload};

    case cardOnFilesActionTypes.ADD_CARD_ON_FILE:

      return {...state, cardOnFiles: [action.payload].concat(state.cardOnFiles)};

    case cardOnFilesActionTypes.REMOVE_CARD_ON_FILE:

      return {...state, cardOnFiles: state.cardOnFiles.filter(card => card.id !== action.id)};

    default:

      return state;
  }
};

export default cardOnFilesReducer;
