import Config from '../../../config/index';
import {postRequest} from './axios-requests';

export default class SearchService {

  static async getSearchProducts(body) {
    try {
      return await postRequest(Config.api.search, body);
    } catch (err) {
      throw err;
    }
  }
}
