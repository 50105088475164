// Action types constants

export const authActionTypes = {
  AUTH_LOADING: 'AUTH_LOADING',
  AUTH_LOADED: 'AUTH_LOADED',
};

export const authLoadingStart = () => ({
  type: authActionTypes.AUTH_LOADING,
});

export const authLoadingSuccess = () => ({
  type: authActionTypes.AUTH_LOADED,
});
