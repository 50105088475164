import {customerHasPasswordActionTypes} from '../actions/customer-has-password';

const customerHasPasswordReducer = (state = true, action) => {

  switch (action.type) {
    case customerHasPasswordActionTypes.CUSTOMER_HAS_PASSWORD:

      return action.payload;

    default:
      return state;
  }
};

export default customerHasPasswordReducer;
