import {firstLevelMenuActionTypes} from '../actions/first-level-menu';

const firstLevelMenuReducer = (state = [], action) => {
  switch (action.type) {
    case firstLevelMenuActionTypes.MENU_FIRST_LEVEL:
      return action.payload;

    default:
      return state;
  }
};

export default firstLevelMenuReducer;
