// Action types constants

export const filtersActionTypes = {
  ALL_FILTERS: 'ALL_FILTERS',
};

export const saveFilters = payload => ({
  type: filtersActionTypes.ALL_FILTERS,
  payload,
});
