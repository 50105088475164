import {completePaymentActionTypes} from '../actions/payment-complete';

const initialState = {
  isPaymentCompleteLoading: false,
  isPaymentCompleteLoaded: false,
  paymentComplete: {},
};

const paymentCompleteReducer = (state = initialState, action) => {

  switch (action.type) {
    case completePaymentActionTypes.UPDATE_PAYMENT_COMPLETE:

      return {...state, paymentComplete: action.payload};

    case completePaymentActionTypes.SET_PAYMENT_COMPLETE_INITIAL_STATE:

      return initialState;

    case completePaymentActionTypes.PAYMENT_COMPLETE_LOADING:

      return {...state, isPaymentCompleteLoading: true};

    case completePaymentActionTypes.PAYMENT_COMPLETE_LOADED:

      return {...state, isPaymentCompleteLoading: false, isPaymentCompleteLoaded: true};

    default:
      return state;
  }
};

export default paymentCompleteReducer;
