import {Component} from 'react';
import {withRouter} from 'react-router-dom';

class CardOnFileIframeTransition extends Component {

  constructor(props) {
    super();

    window.parent.postMessage(props.location.search, '*');
  }

  render() {
    return null;
  }
}

export default withRouter(CardOnFileIframeTransition);
