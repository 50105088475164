import {purchasesActionTypes} from '../actions/purchases';
import Params from '../../shared/services/validation/params';

const paginationOffset = Params.pagination.offset;
const paginationLimit = Params.pagination.limit;

const initialState = {
  isPurchasesLoading: false,
  isPurchasesLoaded: false,
  paginationOffset,
  paginationLimit,
  purchases: [],
  isAllPurchasesLoaded: false,
};

const purchasesReducer = (state = initialState, action) => {

  switch (action.type) {
    case purchasesActionTypes.PURCHASES_LOADING:

      return {...state, isPurchasesLoading: true};

    case purchasesActionTypes.PURCHASES_LOADED:

      return {...state, isPurchasesLoading: false, isPurchasesLoaded: true};

    case purchasesActionTypes.PURCHASES_FIRST_SET_PAGINATION:

      if (!action.payload.length) return initialState;

      return {
        ...state,
        purchases: action.payload,
        paginationOffset: state.paginationLimit,
      };

    case purchasesActionTypes.ALL_PURCHASES:

      return {
        ...state,
        purchases: state.purchases.concat(action.payload),
        paginationOffset: state.paginationOffset + state.paginationLimit,
        isAllPurchasesLoaded: action.payload.length < paginationLimit,
      };

    default:
      return state;
  }
};

export default purchasesReducer;
