import {userActionTypes} from '../actions/user';

const initialState = {
  isUserLoading: false,
  isUserLoaded: false,
  user: {
    avatarImages: [],
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    birthdayMonth: '',
    mailingList: '',
    billingAddress: {
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      region: {label: '', value: ''},
      city: '',
    },
    deliveryAddress: {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      region: {label: '', value: ''},
      phone: '',
    },
    receiptAction: '',
    touchAction: '',
    password: '',
    newPassword: '',
    repeatNewPassword: '',
    hasPassword: false,
  },
};

const userReducer = (state = initialState, action) => {

  switch (action.type) {
    case userActionTypes.USER_LOADING:

      return {...state, isUserLoading: true};

    case userActionTypes.USER_LOADED:

      return {...state, isUserLoading: false, isUserLoaded: true};

    case userActionTypes.SAVE_USER:
      const {billingAddress, deliveryAddress} = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          billingAddress: billingAddress || initialState.user.billingAddress,
          deliveryAddress: deliveryAddress || initialState.user.deliveryAddress,
        },
      };

    case userActionTypes.UPDATE_AVATAR_IMAGES:
      return {
        ...state,
        user: {
          ...state.user,
          avatarImages: action.payload,
        },
      };

    case userActionTypes.SET_INITIAL_STATE:

      return initialState;

    default:
      return state;
  }
};

export default userReducer;
