import {sortingActionTypes} from '../actions/sorting';

const initialSortingState = {
  sort: 'title',
  order: 'asc',
};

const SortingReducer = (state = initialSortingState, action) => {

  switch (action.type) {
    case sortingActionTypes.UPDATE_SORTING_VALUE:

      return {...state, sort: action.payload};

    case sortingActionTypes.UPDATE_SORTING_ORDER:

      return {...state, order: action.payload};

    default:
      return state;
  }
};

export default SortingReducer;
