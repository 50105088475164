// Action types constants

export const cardOnFilesActionTypes = {
  CARD_ON_FILES_LOADING: 'CARD_ON_FILES_LOADING',
  CARD_ON_FILES_LOADED: 'CARD_ON_FILES_LOADED',
  ALL_CARD_ON_FILES: 'ALL_CARD_ON_FILES',
  ADD_CARD_ON_FILE: 'ADD_CARD_ON_FILE',
  REMOVE_CARD_ON_FILE: 'REMOVE_CARD_ON_FILE',
  CARD_ON_FILE_ID: 'CARD_ON_FILE_ID',
};

export const cardOnFilesLoadingStart = () => ({
  type: cardOnFilesActionTypes.CARD_ON_FILES_LOADING,
});

export const cardOnFilesLoadingSuccess = () => ({
  type: cardOnFilesActionTypes.CARD_ON_FILES_LOADED,
});

export const saveCardOnFiles = payload => ({
  type: cardOnFilesActionTypes.ALL_CARD_ON_FILES,
  payload,
});

export const saveCardOnFileId = payload => ({
  type: cardOnFilesActionTypes.CARD_ON_FILE_ID,
  payload,
});

export const addCardOnFiles = payload => ({
  type: cardOnFilesActionTypes.ADD_CARD_ON_FILE,
  payload,
});

export const removeCardOnFiles = id => ({
  type: cardOnFilesActionTypes.REMOVE_CARD_ON_FILE,
  id,
});
