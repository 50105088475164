import {pickUpDeliveryDataActionTypes} from '../actions/pick-up-delivery-data';

const initialState = {
  date: '',
  time: '',
  notes: '',
};

const PickUpDeliveryDataReducer = (state = initialState, action) => {

  switch (action.type) {
    case pickUpDeliveryDataActionTypes.PICK_UP_DELIVERY_DATA:
      return {
        ...state,
        date: action.payload.date,
        time: action.payload.time,
        notes: action.payload.notes,
      };

    case pickUpDeliveryDataActionTypes.REMOVE_PICK_UP_DELIVERY_DATA:

      return initialState;

    default:
      return state;
  }
};

export default PickUpDeliveryDataReducer;
