// Action types constants

export const salesOutletIdActionTypes = {
  UPDATE_SALES_OUTLET_ID: 'UPDATE_SALES_OUTLET_ID',
  UPDATE_LOCATION_ID: 'UPDATE_LOCATION_ID',
};

export const updateSalesOutletId = payload => ({
  type: salesOutletIdActionTypes.UPDATE_SALES_OUTLET_ID,
  payload,
});

export const updateLocationId = payload => ({
  type: salesOutletIdActionTypes.UPDATE_LOCATION_ID,
  payload,
});
