import store from '../../../../store';
import AuthService from '../auth';
import {updateToken} from '../../../../store/actions/token';
import LocalStorage, {localStorageItems} from '../../../localstorage';
import {updateActiveDeliveryType} from '../../../../store/actions/active-delivery-type';
import {clearCart} from '../../../../store/actions/cart';
import {removePickUpDeliveryData} from '../../../../store/actions/pick-up-delivery-data';
import {removeLocalDeliveryData} from '../../../../store/actions/local-delivery-data';
import {removeShipDeliveryData} from '../../../../store/actions/ship-delivery-data';
import {removePaymentData} from '../../../../store/actions/payment-data';
import {setFirstAppVisit} from '../../../../store/actions/first-app-visit';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = accessToken => {
  subscribers = subscribers.map(callback => callback(accessToken));
};

const addSubscriber = callback => {
  subscribers.push(callback);
};

const refreshToken = async () => {
  const {salesOutletId} = LocalStorage.get(localStorageItems.salesOutlet);
  const token = await AuthService.getGuestToken(salesOutletId);
  store.dispatch(updateToken(token));

  return token;
};

const clearLocalStorageData = () => {
  store.dispatch(clearCart());
  store.dispatch(updateActiveDeliveryType(''));
  store.dispatch(removePickUpDeliveryData());
  store.dispatch(removePaymentData());
  store.dispatch(removeShipDeliveryData());
  store.dispatch(removeLocalDeliveryData());
  // store.dispatch(setFirstAppVisit(false));
};

export default async (config, instance) => {
  const originalRequest = config;
  const {customerId} = store.getState().token;
  let newToken;
  const isProfilePrevoiusLocation = window.location.pathname.includes('profile');

  const retryOriginalRequest = new Promise(resolve => {
    addSubscriber(accessToken => {
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      resolve(instance(originalRequest));
    });
  });

  if (!isAlreadyFetchingAccessToken) {
    clearLocalStorageData();
    if (customerId && (customerId !== 1)) {
      window.location.hash = '#sign-in';
    }

    isAlreadyFetchingAccessToken = true;
    newToken = await refreshToken(config);

    isAlreadyFetchingAccessToken = false;
    onAccessTokenFetched(newToken.accessToken);

    store.dispatch(setFirstAppVisit(false));
    if (isProfilePrevoiusLocation) {
      LocalStorage.set('e_shop_last_path', 'profile');
    }
  }

  return retryOriginalRequest;
};
