import {createStore, applyMiddleware} from 'redux';
// import {createLogger} from 'redux-logger';
import {composeWithDevTools} from 'redux-devtools-extension';
import throttle from 'lodash/throttle';
import thunk from 'redux-thunk';
import reducers from './reducers';
import LocalStorage, {localStorageItems} from '../shared/localstorage';
import initialState from './initial-state';

const configureStore = () => {
  // const logger = createLogger();

  const middleware = process.env.NODE_ENV === 'development' ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk);

  const store = createStore(
    reducers,
    initialState(),
    middleware,
  );

  store.subscribe(throttle(() => {
    LocalStorage.set(localStorageItems.token, store.getState().token.accessToken);
    LocalStorage.set(localStorageItems.tokenExpiresIn, store.getState().token.expiresIn);
    LocalStorage.set(localStorageItems.customerId, store.getState().token.customerId);
    LocalStorage.set(localStorageItems.sorting, store.getState().sorting);
    LocalStorage.set(localStorageItems.cart, store.getState().cart);
    LocalStorage.set(localStorageItems.activeDeliveryType, store.getState().activeDeliveryType);
    LocalStorage.set(localStorageItems.firstAppVisit, store.getState().firstAppVisit);
    LocalStorage.set(localStorageItems.salesOutlet, store.getState().salesOutlet);
    LocalStorage.set(localStorageItems.customerHasPassword, store.getState().customerHasPassword);
    LocalStorage.set(localStorageItems.shipDeliveryData, store.getState().shipDeliveryData);
    LocalStorage.set(localStorageItems.pickUpDeliveryData, store.getState().pickUpDeliveryData);
    LocalStorage.set(localStorageItems.localDeliveryData, store.getState().localDeliveryData);
    LocalStorage.set(localStorageItems.paymentData, store.getState().paymentData);
    LocalStorage.set(localStorageItems.applicationUuid, store.getState().applicationUuid);
    LocalStorage.set(localStorageItems.paymentMethod, store.getState().paymentMethod);
  }, 1000));

  return store;
};

export default configureStore();
