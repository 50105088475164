// Action types constants

export const giftCardsActionTypes = {
  GIFT_CARDS_LOADING: 'GIFT_CARDS_LOADING',
  GIFT_CARDS_LOADED: 'GIFT_CARDS_LOADED',
  ALL_GIFT_CARDS: 'ALL_GIFT_CARDS',
};

export const giftCardsLoadingStart = () => ({
  type: giftCardsActionTypes.GIFT_CARDS_LOADING,
});

export const giftCardsLoadingSuccess = () => ({
  type: giftCardsActionTypes.GIFT_CARDS_LOADED,
});

export const saveGiftCards = payload => ({
  type: giftCardsActionTypes.ALL_GIFT_CARDS,
  payload,
});
