const Params = {
  postalCodeMinLength: 4,
  phoneMinLength: 6,
  passwordMinLength: 6,

  pagination: {
    limit: 20,
    offset: 0,
    offsetPageAmount: 1,
  },
};

export default Params;
