import {localDeliveryDistanceActionTypes} from '../actions/local-delivery-distance';

const initialState = {
  isLocalDeliveryDistanceLoading: false,
  isLocalDeliveryDistanceLoaded: false,
  obtainingWarehouseId: '',
  localDeliveryDistance: '',
};

const localDeliveryDistanceReducer = (state = initialState, action) => {

  switch (action.type) {
    case localDeliveryDistanceActionTypes.DISTANCE_LOADING:

      return {...state, isLocalDeliveryDistanceLoading: true};

    case localDeliveryDistanceActionTypes.DISTANCE_LOADED:

      return {...state, isLocalDeliveryDistanceLoading: false, isLocalDeliveryDistanceLoaded: true};

    case localDeliveryDistanceActionTypes.DISTANCE:
      const {obtainingWarehouseId, localDeliveryDistance} = action.payload;

      return {...state, obtainingWarehouseId, localDeliveryDistance};

    case localDeliveryDistanceActionTypes.DISTANCE_REMOVE:

      return initialState;

    default:
      return state;
  }
};

export default localDeliveryDistanceReducer;
