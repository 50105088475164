// Action types constants

export const checkoutCalculationsActionTypes = {
  CHECKOUT_CALCULATIONS: 'CHECKOUT_CALCULATIONS',
  CHECKOUT_CALCULATIONS_LOADING: 'CHECKOUT_CALCULATIONS_LOADING',
  CHECKOUT_CALCULATIONS_LOADED: 'CHECKOUT_CALCULATIONS_LOADED',
};

export const checkoutCalculationsLoadingStart = () => ({
  type: checkoutCalculationsActionTypes.CHECKOUT_CALCULATIONS_LOADING,
});

export const checkoutCalculationsLoadingSuccess = () => ({
  type: checkoutCalculationsActionTypes.CHECKOUT_CALCULATIONS_LOADED,
});

export const saveCheckoutCalculations = payload => ({
  type: checkoutCalculationsActionTypes.CHECKOUT_CALCULATIONS,
  payload,
});
