import LocalStorage, {localStorageItems} from '../shared/localstorage';
import Config from '../config';

const persistedToken = LocalStorage.get(localStorageItems.token);
const persistedTokenExpiresIn = LocalStorage.get(localStorageItems.tokenExpiresIn);
const persistedUserId = LocalStorage.get(localStorageItems.customerId);
const persistedSorting = LocalStorage.get(localStorageItems.sorting);
const persistedCart = LocalStorage.get(localStorageItems.cart);
const persistedShipDeliveryData = LocalStorage.get(localStorageItems.shipDeliveryData);
const persistedPickUpDeliveryData = LocalStorage.get(localStorageItems.pickUpDeliveryData);
const persistedLocalDeliveryData = LocalStorage.get(localStorageItems.localDeliveryData);
const persistedActiveDeliveryType = LocalStorage.get(localStorageItems.activeDeliveryType);
const persistedFirstAppVisit = LocalStorage.get(localStorageItems.firstAppVisit);
const persistedPaymentData = LocalStorage.get(localStorageItems.paymentData);
const persistedSalesOutlet = LocalStorage.get(localStorageItems.salesOutlet);
const persistedCustomerHasPassword = LocalStorage.get(localStorageItems.customerHasPassword);
const persistedApplicationUuid = LocalStorage.get(localStorageItems.applicationUuid);
const persistedPaymentMethod = LocalStorage.get(localStorageItems.paymentMethod);

const newApplicationUuid = Config.applicationUuid;

export default () => {
  if (persistedApplicationUuid !== newApplicationUuid) {
    LocalStorage.clear();
    return {
      applicationUuid: newApplicationUuid,
    };
  }

  return {
    token: {
      accessToken: persistedToken,
      customerId: persistedUserId,
      expiresIn: persistedTokenExpiresIn,
    },
    sorting: persistedSorting,
    cart: persistedCart,
    shipDeliveryData: persistedShipDeliveryData,
    pickUpDeliveryData: persistedPickUpDeliveryData,
    localDeliveryData: persistedLocalDeliveryData,
    activeDeliveryType: persistedActiveDeliveryType,
    firstAppVisit: persistedFirstAppVisit,
    paymentData: persistedPaymentData,
    salesOutlet: persistedSalesOutlet,
    customerHasPassword: persistedCustomerHasPassword,
    applicationUuid: persistedApplicationUuid || newApplicationUuid,
    paymentMethod: persistedPaymentMethod,
  };
};
