import {applicationUuidActionTypes} from '../actions/application-uuid';

const applicationUuidReducer = (state = '', action) => {

  switch (action.type) {
    case applicationUuidActionTypes.APPLICATION_UUID:

      return action.payload;

    default:
      return state;
  }
};

export default applicationUuidReducer;
