// Action types constants

export const searchResultActionTypes = {
  SEARCH_RESULT: 'SEARCH_RESULT',
};

export const updateSearchResult = payload => ({
  type: searchResultActionTypes.SEARCH_RESULT,
  payload,
});
