import {categoriesActionTypes} from '../actions/categories';

const initialState = {
  isCategoriesLoading: false,
  isCategoriesLoaded: false,
  categories: [],
};

const categoriesReducer = (state = initialState, action) => {

  switch (action.type) {
    case categoriesActionTypes.CATEGORIES_LOADING:

      return {...state, isCategoriesLoading: true};

    case categoriesActionTypes.CATEGORIES_LOADED:

      return {...state, isCategoriesLoading: false, isCategoriesLoaded: true};

    case categoriesActionTypes.ALL_CATEGORIES:

      return {...state, categories: action.payload};

    default:
      return state;
  }
};

export default categoriesReducer;
