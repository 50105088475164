// Action types constants

export const completePaymentActionTypes = {
  PAYMENT_COMPLETE_LOADING: 'PAYMENT_COMPLETE_LOADING',
  PAYMENT_COMPLETE_LOADED: 'PAYMENT_COMPLETE_LOADED',
  UPDATE_PAYMENT_COMPLETE: 'UPDATE_PAYMENT_COMPLETE',
  SET_PAYMENT_COMPLETE_INITIAL_STATE: 'SET_PAYMENT_COMPLETE_INITIAL_STATE',
};

export const paymentCompleteLoadingStart = () => ({
  type: completePaymentActionTypes.PAYMENT_COMPLETE_LOADING,
});

export const paymentCompleteLoadingSuccess = () => ({
  type: completePaymentActionTypes.PAYMENT_COMPLETE_LOADED,
});

export const updatePaymentComplete = payload => ({
  type: completePaymentActionTypes.UPDATE_PAYMENT_COMPLETE,
  payload,
});

export const setPaymentCompleteInitialState = () => ({
  type: completePaymentActionTypes.SET_PAYMENT_COMPLETE_INITIAL_STATE,
});
