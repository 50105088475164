import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Notifications from 'react-notification-system-redux';

import './notification.scss';
import '../../../assets/styles/common.scss';

const style = {
  Containers: {
    DefaultStyle: {
      width: '294px',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
      padding: '20px 58px 20px 16px',
      height: 'auto',
      minHeight: '80px',
      borderTop: 'none',
    },

    success: {
      backgroundColor: '#D2EECC',
    },
    error: {
      backgroundColor: '#F6D5D5',
    },
  },
  Title: {
    DefaultStyle: {
      color: '#020319',
      fontSize: '16px',
      textTransform: 'uppercase',
      position: 'absolute',
      zIndex: 9996,
    },
  },
  MessageWrapper: {
    DefaultStyle: {
      color: '#020319',
      fontSize: '14px',
      position: 'relative',
      marginTop: '22px',
      zIndex: 9996,
      wordBreak: 'break-word',
    },
  },
  Dismiss: {
    DefaultStyle: {
      display: 'none',
    },
  },
};

class Notification extends PureComponent {

  render() {
    const {notifications} = this.props;

    return (
      <Notifications notifications={notifications} style={style} />
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
});

Notification.contextTypes = {
  store: PropTypes.object,
};

export default connect(mapStateToProps)(Notification);
