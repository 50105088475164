import axios from 'axios/index';

import store from '../../../../store/index';
import handleError from '../error-handlers/check-status';
import Config from '../../../../config';

const instance = axios.create({
  baseURL: Config.apiUrl,
});

instance.interceptors.response.use(
  response => response,
  error => handleError(error, instance),
);

export const postRequest = async (url, data, headers, responseType) => {
  const {accessToken} = store.getState().token;
  const response = await instance.post(url, data, {
    headers: {Authorization: `Bearer ${accessToken}`, ...headers},
    responseType: responseType || '',
  });

  return response.data;
};
