import {paymentMethodActionTypes} from '../actions/payment-method';

const paymentMethodReducer = (state = '', action) => {

  switch (action.type) {
    case paymentMethodActionTypes.UPDATE_PAYMENT_METHOD:
      return action.payload;

    default:
      return state;
  }
};

export default paymentMethodReducer;
