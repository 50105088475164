// Action types constants

export const cardOnFilesAccountActionTypes = {
  CARD_ON_FILES_ACCOUNT_LOADING: 'CARD_ON_FILES_ACCOUNT_LOADING',
  CARD_ON_FILES_ACCOUNT_LOADED: 'CARD_ON_FILES_ACCOUNT_LOADED',
};

export const cardOnFilesAccountLoadingStart = () => ({
  type: cardOnFilesAccountActionTypes.CARD_ON_FILES_ACCOUNT_LOADING,
});

export const cardOnFilesAccountLoadingSuccess = () => ({
  type: cardOnFilesAccountActionTypes.CARD_ON_FILES_ACCOUNT_LOADED,
});
