// Action types constants

export const firstLevelMenuActionTypes = {
  MENU_FIRST_LEVEL: 'MENU_FIRST_LEVEL',
};

export const saveFirstLevelMenu = payload => ({
  type: firstLevelMenuActionTypes.MENU_FIRST_LEVEL,
  payload,
});
