// Action types constants
export const localDeliveryDataActionTypes = {
  LOCAL_DELIVERY_DATA: 'LOCAL_DELIVERY_DATA',
  REMOVE_LOCAL_DELIVERY_DATA: 'REMOVE_LOCAL_DELIVERY_DATA',
};

export const updateLocalDeliveryData = payload => ({
  type: localDeliveryDataActionTypes.LOCAL_DELIVERY_DATA,
  payload,
});

export const removeLocalDeliveryData = () => ({
  type: localDeliveryDataActionTypes.REMOVE_LOCAL_DELIVERY_DATA,
});
