import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import 'babel-polyfill';
import 'url-search-params-polyfill';

import './assets/styles/index.scss';
import App from './shared/container-components/app/app-container';
import store from './store';
import ScrollIntoView from './shared/components/scroll-into-view';
import DefaultContext from './contexts/default';

window.s = store;

ReactDOM.render(
  <DefaultContext.Provider value={{store}}>
    <Provider store={store}>
      <Router>
        <ScrollIntoView />
        <App />
      </Router>
    </Provider>
  </DefaultContext.Provider>,
  document.getElementById('root'),
);
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    /* eslint-disable-next-line */
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
