import {searchResultActionTypes} from '../actions/search-result';

const searchResultReducer = (state = [], action) => {

  switch (action.type) {
    case searchResultActionTypes.SEARCH_RESULT:

      return action.payload;

    default:
      return state;
  }
};

export default searchResultReducer;
