import {combineReducers} from 'redux';
import {reducer as notifications} from 'react-notification-system-redux';

import token from './token';
import products from './products';
import product from './product';
import menu from './menu';
import firstLevelMenu from './first-level-menu';
import filters from './filters';
import sorting from './sorting';
import config from './config';
import theme from './theme';
import searchResult from './search-result';
import searchValue from './search-value';
import customPage from './custom-page';
import categories from './categories';
import cart from './cart';
import checkoutOptions from './checkout-options';
import checkoutRates from './checkout-rates';
import checkoutCalculations from './checkout-calculations';
import shipDeliveryData from './ship-delivery-data';
import pickUpDeliveryData from './pick-up-delivery-data';
import localDeliveryData from './local-delivery-data';
import activeDeliveryType from './active-delivery-type';
import payment from './payment';
import paymentComplete from './payment-complete';
import paymentMethod from './payment-method';
import firstAppVisit from './first-app-visit';
import auth from './auth';
import paymentData from './payment-data';
import giftCard from './gift-card';
import giftCards from './gift-cards';
import cardOnFiles from './card-on-files';
import cardOnFilesAccount from './card-on-files-account';
import giftCardCustomAmount from './gift-card-custom-amount';
import user from './user';
import salesOutlet from './sales-outlet';
import localDeliveryDistance from './local-delivery-distance';
import purchases from './purchases';
import customerHasPassword from './customer-has-password';
import applicationUuid from './application-uuid';

const reducers = combineReducers({
  token,
  filters,
  sorting,
  products,
  product,
  menu,
  firstLevelMenu,
  config,
  theme,
  searchResult,
  searchValue,
  customPage,
  categories,
  cart,
  notifications,
  checkoutOptions,
  checkoutRates,
  checkoutCalculations,
  shipDeliveryData,
  pickUpDeliveryData,
  localDeliveryData,
  activeDeliveryType,
  payment,
  paymentComplete,
  paymentMethod,
  firstAppVisit,
  auth,
  paymentData,
  giftCard,
  giftCards,
  cardOnFiles,
  cardOnFilesAccount,
  giftCardCustomAmount,
  user,
  salesOutlet,
  localDeliveryDistance,
  purchases,
  customerHasPassword,
  applicationUuid,
});

export default reducers;
