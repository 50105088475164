import Config from '../../../config/index';
import {postRequest} from './axios-requests';

export default class ProductService {

  static async getFilters(categoryId) {
    try {
      const body = {categoryId};

      return await postRequest(Config.api.filters, body);
    } catch (err) {
      throw err;
    }
  }

  static async getProducts(body) {
    try {
      return await postRequest(Config.api.products, body);
    } catch (err) {
      throw err;
    }
  }

  static async getProduct(productId) {
    try {
      const body = {productId};

      return await postRequest(Config.api.product, body);
    } catch (err) {
      throw err;
    }
  }

  static async cartValidate(products) {
    try {
      const body = {products};

      return await postRequest(Config.api.cartValidate, body);
    } catch (err) {
      throw err;
    }
  }

  static async calculateCartProducts(body) {
    try {
      return await postRequest(Config.api.cartCalculate, body);
    } catch (err) {
      throw err;
    }
  }
}
