import {authActionTypes} from '../actions/auth';

const initialState = {
  isAuthLoading: false,
  isAuthLoaded: false,
};

const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case authActionTypes.AUTH_LOADING:

      return {...state, isAuthLoading: true};

    case authActionTypes.AUTH_LOADED:

      return {...state, isAuthLoading: false, isAuthLoaded: true};

    default:
      return state;
  }
};

export default authReducer;
