// Action types constants

export const sortingActionTypes = {
  UPDATE_SORTING_VALUE: 'UPDATE_SORTING_VALUE',
  UPDATE_SORTING_ORDER: 'UPDATE_SORTING_ORDER',
};

export const updateSortingValue = payload => ({
  type: sortingActionTypes.UPDATE_SORTING_VALUE,
  payload,
});

export const updateSortingOrder = payload => ({
  type: sortingActionTypes.UPDATE_SORTING_ORDER,
  payload,
});
