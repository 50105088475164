import React, {PureComponent} from 'react';
import {Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import RegistrationLinkContainer from '../registration-link/registration-link-container';
import Notification from '../../../themes/instacart/shared/components/notification/notification';
import PaymentIframeTransition from '../complete-payment/payment-iframe-transition';
import CardOnFileIframeTransition from '../profile/card-on-file-iframe-transition';
import {saveApplicationUuid} from '../../../store/actions/application-uuid';
import LocalStorage from '../../localstorage';

// const theme = process.env.REACT_APP_THEME;

const AppThemeComponent = Loadable({
  // loader: () => import(`../../../themes/${theme}/components/app/App`),
  loader: () => import('../../../themes/instacart/components/app/App'),
  loading: () => <div />,
});

class AppContainer extends PureComponent {

  render() {
    const {location} = this.props;

    const {pathname} = location;
    const paymentMethod = LocalStorage.get('e_shop_payment_method');

    if (pathname.startsWith('/link/')) {
      return (
        <div>
          <Route
            path="/link/:customerId/:accessToken"
            component={RegistrationLinkContainer}
          />
          <Notification />
        </div>
      );
    }
    if ((pathname === '/complete-payment-transitional')
      || (pathname === '/iframe-transition')) {
      return (
        <div>
          <Route
            path="/complete-payment-transitional"
            // component={PaymentIframeTransition}
            render={props => {
              return (
                <PaymentIframeTransition
                  paymentMethod={paymentMethod}
                  {...props}
                />
              );
            }}
          />

          <Route
            path="/iframe-transition"
            component={CardOnFileIframeTransition}
          />
        </div>
      );
    }

    return <AppThemeComponent />;
  }
}

const mapStateToProps = state => {
  return {
    applicationUuid: state.applicationUuid,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    saveApplicationUuid,
  }, dispatch);
};

AppContainer.contextTypes = {
  store: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
