// Action types constants

export const firstAppVisitActionTypes = {
  FIRST_VISIT: 'FIRST_VISIT',
};

export const setFirstAppVisit = payload => ({
  type: firstAppVisitActionTypes.FIRST_VISIT,
  payload,
});
