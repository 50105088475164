import handle400Error from './400';
import handle403Error from './403';
import handle409Error from './409';
import handle413Error from './413';

export default (error, instance) => {
  const {response, config} = error;
  const {status} = response;

  switch (status) {
    case 400: return handle400Error(response);
    case 403: return handle403Error(config, instance);
    case 409: return handle409Error(response);
    case 413: return handle413Error(response);
    default: throw error;
  }
};
