// Action types constants

export const tokenActionTypes = {
  TOKEN_LOADING: 'TOKEN_LOADING',
  TOKEN_LOADED: 'TOKEN_LOADED',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  DELETE_TOKEN: 'DELETE_TOKEN',
};

export const tokenLoadingStart = () => ({
  type: tokenActionTypes.TOKEN_LOADING,
});

export const tokenLoadingSuccess = () => ({
  type: tokenActionTypes.TOKEN_LOADED,
});

export const updateToken = payload => ({
  type: tokenActionTypes.UPDATE_TOKEN,
  payload,
});

export const deleteToken = () => ({
  type: tokenActionTypes.DELETE_TOKEN,
});
