import {activeDeliveryTypeActionTypes} from '../actions/active-delivery-type';

const activeDeliveryTypeReducer = (state = '', action) => {

  switch (action.type) {
    case activeDeliveryTypeActionTypes.ACTIVE_DELIVERY_TYPE:

      return action.payload;

    default:
      return state;
  }
};

export default activeDeliveryTypeReducer;
