import {giftCardActionTypes} from '../actions/gift-card';

const initialState = {
  isGiftCardLoading: false,
  isGiftCardLoaded: false,
  giftCard: {},
};

const giftCardReducer = (state = initialState, action) => {

  switch (action.type) {
    case giftCardActionTypes.GIFT_CARD_LOADING:

      return {...state, isGiftCardLoading: true};

    case giftCardActionTypes.GIFT_CARD_LOADED:

      return {...state, isGiftCardLoading: false, isGiftCardLoaded: true};

    case giftCardActionTypes.UPDATE_GIFT_CARD:

      return {...state, giftCard: action.payload};

    case giftCardActionTypes.SET_GIFT_CARD_INITIAL_STATE:

      return initialState;

    default:
      return state;
  }
};

export default giftCardReducer;
