export const compareModifiers = (array1, array2) => {
  if (!array1 && !array2) return true;
  if (array1.length === 0 && array2.length === 0) return true;
  if (!array1 && array2) return false;
  if (array1 && !array2) return false;
  if (array1.length !== array2.length) return false;

  const isDataEqual = array1.every(item1 => array2.find(item2 => {
    return (item2.modifierId === item1.modifierId) && (item2.groupId === item1.groupId);
  }));

  return (isDataEqual);
};
