// Action types constants

export const productActionTypes = {
  SAVE_PRODUCT: 'SAVE_PRODUCT',
  PRODUCT_LOADING: 'PRODUCT_LOADING',
  PRODUCT_LOADED: 'PRODUCT_LOADED',
};

export const productLoadingStart = () => ({
  type: productActionTypes.PRODUCT_LOADING,
});

export const productLoadingSuccess = () => ({
  type: productActionTypes.PRODUCT_LOADED,
});

export const saveProduct = payload => ({
  type: productActionTypes.SAVE_PRODUCT,
  payload,
});
