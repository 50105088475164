import {themeActionTypes} from '../actions/theme';

const initialState = {
  isThemeLoading: false,
  isThemeLoaded: false,
  theme: {},
};

const themeReducer = (state = initialState, action) => {

  switch (action.type) {
    case themeActionTypes.THEME_LOADING:

      return {...state, isThemeLoading: true};

    case themeActionTypes.THEME_LOADED:

      return {...state, isThemeLoading: false, isThemeLoaded: true};

    case themeActionTypes.UPDATE_THEME:

      return {...state, theme: action.payload};

    default:
      return state;
  }
};

export default themeReducer;
