import {error} from 'react-notification-system-redux';

import ProductService from '../../shared/services/api/product';
import SearchService from '../../shared/services/api/search';
import {errorOpts} from '../../shared/services/notification/notification-options';
import Params from '../../shared/services/validation/params';
import {saveFilters} from './filters';

// Action types constants
export const productsActionTypes = {
  PRODUCTS_LOADING: 'PRODUCTS_LOADING',
  PRODUCTS_LOADED: 'PRODUCTS_LOADED',
  FIRST_SET_PRODUCTS: 'FIRST_SET_PRODUCTS',
  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
};

export const productsLoadingStart = () => ({
  type: productsActionTypes.PRODUCTS_LOADING,
});

export const productsLoadingSuccess = () => ({
  type: productsActionTypes.PRODUCTS_LOADED,
});

export const saveFirstSetProducts = products => ({
  type: productsActionTypes.FIRST_SET_PRODUCTS,
  products,
});

export const updateProducts = (products, offset) => ({
  type: productsActionTypes.UPDATE_PRODUCTS,
  products,
  offset,
});

export const setProductsList = (isPagination, body, offset) => async dispatch => {
  dispatch(productsLoadingStart());

  try {
    let products = await ProductService.getProducts(body);

    if (!products.data) products = [];

    if (isPagination) {
      dispatch(updateProducts(products, offset));
    } else {
      dispatch(saveFirstSetProducts(products));
    }
  } catch (err) {
    dispatch(error(errorOpts(err.message)));
  } finally {
    dispatch(productsLoadingSuccess());
  }
};

export const setProductsListBySearch = (isPagination, body, offset) => async dispatch => {
  dispatch(productsLoadingStart());

  try {
    let products = await SearchService.getSearchProducts(body);

    if (!products.data) products = [];

    if (isPagination) {
      dispatch(updateProducts(products, offset));
    } else {
      dispatch(saveFirstSetProducts(products));
    }
  } catch (err) {
    dispatch(error(errorOpts(err.message)));
  } finally {
    dispatch(productsLoadingSuccess());
  }
};

export const getFilters = categoryId => async dispatch => {
  const filters = await ProductService.getFilters(categoryId);

  dispatch(saveFilters(filters));

  return filters;
};

export const getProducts = ({
  categoryId,
  offset,
  limit,
  isPagination,
  selectedFilters,
}) => async (dispatch, getState) => {
  const {sorting} = getState();
  const [minPrice, maxPrice] = selectedFilters.priceRange;
  const brandIds = selectedFilters.brands;
  const {customAttributes} = selectedFilters;
  const body = {
    categoryId,
    brandIds,
    priceFrom: minPrice,
    priceTo: maxPrice,
    offset,
    limit,
    customAttributes,
    ...sorting,
  };

  await dispatch(setProductsList(isPagination, body, offset));
};

export const getFirstSetProducts = (categoryId, selectedFilters) => async dispatch => {
  const {
    offset,
    limit,
  } = Params.pagination;
  const params = {
    categoryId,
    offset,
    limit,
    isPagination: false,
    selectedFilters,
  };

  try {
    dispatch(productsLoadingStart());
    await dispatch(getFilters(categoryId));
    await dispatch(getProducts({...params}));
  } catch (err) {
    dispatch(productsLoadingSuccess());
    dispatch(error(errorOpts(err.message)));
  } finally {
    dispatch(productsLoadingSuccess());
  }
};

export const getPaginationProducts = (page, categoryId, selectedFilters) => async (dispatch, getState) => {
  const {products} = getState();

  const {offsetPageAmount} = Params.pagination;
  const {paginationLimit: limit} = products;
  const offset = limit * (page - offsetPageAmount);
  const isPagination = true;
  const params = {
    categoryId,
    offset,
    limit,
    isPagination,
    selectedFilters,
  };

  await dispatch(getFilters(categoryId));
  await dispatch(getProducts({...params}));
};
