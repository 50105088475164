import {configActionTypes} from '../actions/config';

const initialState = {
  isConfigLoading: false,
  isConfigLoaded: false,
  config: {},
};

const configReducer = (state = initialState, action) => {

  switch (action.type) {
    case configActionTypes.CONFIG_LOADING:

      return {...state, isConfigLoading: true};

    case configActionTypes.CONFIG_LOADED:

      return {...state, isConfigLoading: false, isConfigLoaded: true};

    case configActionTypes.UPDATE_CONFIG:

      return {...state, config: action.payload};

    default:
      return state;
  }
};

export default configReducer;
