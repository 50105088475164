import {localDeliveryDataActionTypes} from '../actions/local-delivery-data';

const initialState = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  date: '',
  time: '',
  notes: '',
};

const localDeliveryDataReducer = (state = initialState, action) => {

  switch (action.type) {
    case localDeliveryDataActionTypes.LOCAL_DELIVERY_DATA:

      return {...state, ...action.payload};

    case localDeliveryDataActionTypes.REMOVE_LOCAL_DELIVERY_DATA:

      return initialState;

    default:
      return state;
  }
};

export default localDeliveryDataReducer;
