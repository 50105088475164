import {error} from 'react-notification-system-redux';

import CheckoutService from '../../shared/services/api/checkout';
import {errorOpts} from '../../shared/services/notification/notification-options';

// Action types constants
export const checkoutOptionsActionTypes = {
  CHECKOUT_OPTIONS_LOADING: 'CHECKOUT_OPTIONS_LOADING',
  CHECKOUT_OPTIONS_LOADED: 'CHECKOUT_OPTIONS_LOADED',
  CHECKOUT_OPTIONS: 'CHECKOUT_OPTIONS',
};

export const checkoutOptionsLoadingStart = () => ({
  type: checkoutOptionsActionTypes.CHECKOUT_OPTIONS_LOADING,
});

export const checkoutOptionsLoadingSuccess = () => ({
  type: checkoutOptionsActionTypes.CHECKOUT_OPTIONS_LOADED,
});

export const saveCheckoutOptions = payload => ({
  type: checkoutOptionsActionTypes.CHECKOUT_OPTIONS,
  payload,
});

export const getZipCodeList = () => async dispatch => {
  dispatch(checkoutOptionsLoadingStart());

  try {
    return await CheckoutService.getZipCodeList();
  } catch (err) {
    dispatch(error(errorOpts(err.message)));
  } finally {
    dispatch(checkoutOptionsLoadingSuccess());
  }
};
