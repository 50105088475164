import {menuActionTypes} from '../actions/menu';

const menuReducer = (state = [], action) => {

  switch (action.type) {
    case menuActionTypes.MENU:

      return action.payload;

    default:
      return state;
  }
};

export default menuReducer;
