// Action types constants

export const menuActionTypes = {
  MENU: 'MENU',
};

export const saveMenu = payload => ({
  type: menuActionTypes.MENU,
  payload,
});
