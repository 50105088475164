import {cartActionTypes} from '../actions/cart';
import {compareModifiers} from '../../shared/utils/compare-modifiers';

const initialState = {
  products: [],
  shippingAmount: '',
  subTotalAmount: '',
  totalAmount: '',
  totalDiscountAmount: '',
  totalSaleAmount: '',
  totalTaxAmount: '',
  isCalculation: false,
  coupons: {
    enteredCoupon: '',
    couponsList: [],
  },
};

const cartReducer = (state = initialState, action) => {

  switch (action.type) {

    case cartActionTypes.UPDATE_CART_PRODUCTS:

      return {...state, products: action.payload};

    case cartActionTypes.ADD_TO_CART:

      return {...state, products: state.products.concat(action.payload)};

    case cartActionTypes.UPDATE_PRODUCT_QUANTITY:

      return {
        ...state,
        products: state.products.map(product => {
          const isEqualIds = (product.product.id === action.id);

          if (!action.modifiers && isEqualIds) {
            return {...product, quantity: action.quantity};
          }
          if (isEqualIds && compareModifiers(product.modifiers, action.modifiers)) {
            return {...product, quantity: action.quantity, modifiers: action.modifiers};
          }

          return product;
        }),
      };

    case cartActionTypes.REMOVE_CART_ITEM:

      return {
        ...state,
        products: state.products.filter(item => {
          const isEqualIds = (item.product.id === action.id);

          return !(isEqualIds && compareModifiers(item.modifiers, action.modifiers));
        }),
      };

    case cartActionTypes.UPDATE_CALCULATIONS:
      const products = state.products.map(item => {
        const currentProduct = action.payload.products[item.product.id];
        const productTagalong = currentProduct.tagalongs;
        const {price} = currentProduct;
        const tagalongs = productTagalong ? {tagalongs: productTagalong} : null;

        item.product.isUMFractioned = currentProduct.isUMFractioned; // eslint-disable-line no-param-reassign
        item.product.isManualInput = currentProduct.isManualInput; // eslint-disable-line no-param-reassign

        return {...item, ...tagalongs, price};
      });

      return {
        ...state,
        shippingAmount: action.payload.shippingAmount,
        subTotalAmount: action.payload.subTotalAmount,
        totalAmount: action.payload.totalAmount,
        totalDiscountAmount: action.payload.totalDiscountAmount,
        totalSaleAmount: action.payload.totalSaleAmount,
        totalTaxAmount: action.payload.totalTaxAmount,
        products,
      };

    case cartActionTypes.CLEAR_CART:

      return initialState;

    case cartActionTypes.ADD_ENTERED_COUPON:

      if (state.coupons.couponsList.indexOf(action.payload) !== -1) {
        return state;
      }

      return {...state,
        coupons: {
          ...state.coupons,
          enteredCoupon: action.payload,
        },
      };

    case cartActionTypes.ADD_COUPON_TO_COUPONS_LIST:

      return {...state,
        coupons: {
          ...state.coupons,
          couponsList: [...state.coupons.couponsList, action.payload],
        },
      };

    case cartActionTypes.REMOVE_COUPON:

      const updatedCoupons = state.coupons.couponsList.filter(item => item !== action.payload);
      return {...state,
        coupons: {
          ...state.coupons,
          couponsList: updatedCoupons,
        },
      };

    case cartActionTypes.DELETE_ENTERED_COUPON:

      return {...state,
        coupons: {
          ...state.coupons,
          enteredCoupon: '',
        },
      };

    case cartActionTypes.START_CALCULATION:

      return {...state,
        isCalculation: true,
      };

    case cartActionTypes.STOP_CALCULATION:

      return {...state,
        isCalculation: false,
      };

    default:
      return state;
  }
};

export default cartReducer;
