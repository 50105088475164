import {firstAppVisitActionTypes} from '../actions/first-app-visit';

const initialState = {
  isFirstVisit: true,
};

const FirstAppVisitReducer = (state = initialState, action) => {

  switch (action.type) {
    case firstAppVisitActionTypes.FIRST_VISIT:

      return {...state, isFirstVisit: action.payload};

    default:
      return state;
  }
};

export default FirstAppVisitReducer;
