import Config from '../../../config/index';
import {postRequest} from './axios-requests';

export default class AuthService {

  static async getGuestToken(customSalesOutletId) {
    const {openApiKey, salesOutletId} = Config;
    const body = {openApiKey, salesOutletId, customSalesOutletId};

    return postRequest(Config.api.signin, body);
  }

  static async signIn(values, customSalesOutletId) {
    try {
      const {openApiKey, salesOutletId} = Config;
      const body = {openApiKey, salesOutletId, ...values, customSalesOutletId};

      return await postRequest(Config.api.signin, body);
    } catch (err) {
      throw err;
    }
  }

  static async signUp(values, customSalesOutletId) {
    try {
      const {openApiKey, salesOutletId} = Config;
      const body = {openApiKey, salesOutletId, ...values, customSalesOutletId};

      return await postRequest(Config.api.signup, body);
    } catch (err) {
      throw err;
    }
  }

  static async recoverPassword(email) {
    try {
      const {openApiKey, salesOutletId} = Config;
      const body = {openApiKey, salesOutletId, email};

      return await postRequest(Config.api.forgotPassword, body);
    } catch (err) {
      throw err;
    }
  }

  static async regenerateToken(customSalesOutletId) {
    try {
      const {openApiKey, salesOutletId} = Config;
      const body = {openApiKey, salesOutletId, customSalesOutletId};

      return await postRequest(Config.api.regenerateToken, body);
    } catch (err) {
      throw err;
    }
  }

  static async verifyCustomer(data) {
    try {
      const {openApiKey, salesOutletId} = Config;
      const body = {openApiKey, salesOutletId, ...data};

      return await postRequest(Config.api.verifyCustomer, body);
    } catch (err) {
      throw err;
    }
  }
}
