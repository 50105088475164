export const localStorageItems = {
  token: 'e_shop_token',
  tokenExpiresIn: 'e_shop_token_expires_in',
  customerId: 'e_shop_customer_id',
  sorting: 'e_shop_sorting_value',
  cart: 'e_shop_cart',
  shipDeliveryData: 'e_shop_ship_delivery_data',
  pickUpDeliveryData: 'e_shop_pick_up_delivery_data',
  localDeliveryData: 'e_shop_local_delivery_data',
  activeDeliveryType: 'e_shop_active_delivery_type',
  firstAppVisit: 'e_shop_first_app_visit',
  paymentData: 'e_shop_payment_data',
  salesOutlet: 'e_shop_sales_outlet',
  customerHasPassword: 'e_shop_customer_has_password',
  applicationUuid: 'e_shop_application_uuid',
  paymentMethod: 'e_shop_payment_method',
};

const LocalStorage = {

  get(key) {
    try {
      const serializedState = localStorage.getItem(key);

      if (serializedState === null) {
        return undefined;
      }

      return JSON.parse(serializedState);

    } catch (err) {
      return undefined;
    }
  },

  set(key, data) {
    try {
      const serializedState = JSON.stringify(data);

      return localStorage.setItem(key, serializedState);
    } catch (err) {
      return err;
    }
  },

  remove(key) {
    try {
      return localStorage.removeItem(key);
    } catch (err) {
      return err;
    }
  },

  clear() {
    try {
      localStorage.clear();
    } catch (err) {
      return err;
    }
  },
};

export default LocalStorage;
