// Action types constants

export const purchasesActionTypes = {
  PURCHASES_LOADING: 'PURCHASES_LOADING',
  PURCHASES_LOADED: 'PURCHASES_LOADED',
  ALL_PURCHASES: 'ALL_PURCHASES',
  PURCHASES_FIRST_SET_PAGINATION: 'PURCHASES_FIRST_SET_PAGINATION',
};

export const purchasesLoadingStart = () => ({
  type: purchasesActionTypes.PURCHASES_LOADING,
});

export const purchasesLoadingSuccess = () => ({
  type: purchasesActionTypes.PURCHASES_LOADED,
});

export const savePurchases = payload => ({
  type: purchasesActionTypes.ALL_PURCHASES,
  payload,
});

export const purchasesFirstSetPagination = payload => ({
  type: purchasesActionTypes.PURCHASES_FIRST_SET_PAGINATION,
  payload,
});
