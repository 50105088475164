import {searchValueActionTypes} from '../actions/search-value';

const searchValueReducer = (state = '', action) => {

  switch (action.type) {
    case searchValueActionTypes.UPDATE_SEARCH_VALUE:

      return action.payload;

    default:
      return state;
  }
};

export default searchValueReducer;
