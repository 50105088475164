import {paymentActionTypes} from '../actions/payment';

const initialState = {
  isPaymentLoading: false,
  isPaymentLoaded: false,
  payment: {},
  isPaymentCompleted: false,
};

const paymentReducer = (state = initialState, action) => {

  switch (action.type) {
    case paymentActionTypes.PAYMENT_LOADING:

      return {...state, isPaymentLoading: true};

    case paymentActionTypes.PAYMENT_LOADED:

      return {...state, isPaymentLoading: false, isPaymentLoaded: true};

    case paymentActionTypes.UPDATE_PAYMENT:

      return {...state, payment: action.payload};

    case paymentActionTypes.UPDATE_PAYMENT_STATE:

      return {...state, isPaymentCompleted: action.payload};

    case paymentActionTypes.SET_INITIAL_PAYMENT_STATE:

      return initialState;

    default:
      return state;
  }
};

export default paymentReducer;
