// Action types constants

export const giftCardCustomAmountActionTypes = {
  SAVE_GIFT_CARD_CUSTOM_AMOUNT: 'SAVE_GIFT_CARD_CUSTOM_AMOUNT',
};

export const saveGiftCardCustomAmount = payload => ({
  type: giftCardCustomAmountActionTypes.SAVE_GIFT_CARD_CUSTOM_AMOUNT,
  payload,
});
