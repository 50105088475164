import React from 'react';
import classNames from 'classnames';
import {ReactComponent as SpinnerIcon} from '../../../assets/spinner.svg';
import './spinner.scss';

export default function Spinner({active, customClassName}) {
  const checkoutOverlayClass = classNames('spinner-overlay', {
    active,
    [customClassName]: customClassName,
  });

  return (
    <div className={checkoutOverlayClass}>
      <div className="spinner">
        <SpinnerIcon />
      </div>
    </div>
  );
}
