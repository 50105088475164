import {filtersActionTypes} from '../actions/filters';

const FiltersReducer = (state = {}, action) => {

  switch (action.type) {
    case filtersActionTypes.ALL_FILTERS:

      return action.payload;

    default:
      return state;
  }
};

export default FiltersReducer;
