// Action types constants

export const customPageActionTypes = {
  CUSTOM_PAGE: 'CUSTOM_PAGE',
};

export const saveCustomPage = payload => ({
  type: customPageActionTypes.CUSTOM_PAGE,
  payload,
});
