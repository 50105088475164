import React from 'react';
import {Route} from 'react-router-dom';

export const successCartOpts = message => ({
  level: 'success',
  title: 'Success!',
  autoDismiss: 3,
  message: message || 'Request is successful!',
  children: (
    <div>
      <div className="notification-background notification-success" />
      <Route render={({history}) => (
        <button className="notification-action-button primary-btn " onClick={() => { history.push('/cart'); }}>
          View cart
        </button>
      )}
      />

    </div>
  ),
});

export const successOpts = message => ({
  level: 'success',
  title: 'Success!',
  autoDismiss: 3,
  message: message || 'Request is successful!',
  children: (
    <div className="notification-background notification-success" />
  ),
});

export const errorOpts = message => ({
  level: 'error',
  title: 'Error!',
  autoDismiss: 3,
  message: message || 'Something went wrong...',
  children: (
    <div className="notification-background notification-error" />
  ),
});

export const successCardOnFileOpts = message => ({
  level: 'success',
  title: 'Success!',
  autoDismiss: 0,
  message: message || 'Request is successful!',
  children: (
    <div className="notification-background notification-success" />
  ),
});

export const errorLocationOpts = message => ({
  level: 'error',
  title: 'Error!',
  autoDismiss: 0,
  message: message || 'Something went wrong...',
  children: (
    <div className="notification-background notification-error" />
  ),
});
