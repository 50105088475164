import {productsActionTypes} from '../actions/products';
import Params from '../../shared/services/validation/params';

const paginationOffset = Params.pagination.offset;
const paginationLimit = Params.pagination.limit;

const initialState = {
  isProductsLoading: false,
  isProductsLoaded: false,
  paginationOffset,
  paginationLimit,
  products: [],
  total: null,
  isAllProductsLoaded: false,
};

const productsReducer = (state = initialState, action) => {

  switch (action.type) {
    case productsActionTypes.PRODUCTS_LOADING:

      return {
        ...state,
        isProductsLoading: true,
      };

    case productsActionTypes.PRODUCTS_LOADED:

      return {
        ...state,
        isProductsLoading: false,
        isProductsLoaded: true,
      };

    case productsActionTypes.FIRST_SET_PRODUCTS:

      if (!action.products.data) return initialState;

      return {
        ...state,
        products: (!action.products.data) ? action.products : action.products.data,
        total: (!action.products.count) ? state.total : action.products.count,
        paginationOffset: state.paginationOffset,
      };

    case productsActionTypes.UPDATE_PRODUCTS:

      return {
        ...state,
        products: action.products.data,
        paginationOffset: action.offset,
        total: (!action.products.count) ? state.total : action.products.count,
        isAllProductsLoaded: action.products.data.length < paginationLimit,
      };

    default:
      return state;
  }
};

export default productsReducer;
