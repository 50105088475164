import {giftCardCustomAmountActionTypes} from '../actions/gift-card-custom-amount';

const giftCardCustomAmountReducer = (state = {}, action) => {

  switch (action.type) {
    case giftCardCustomAmountActionTypes.SAVE_GIFT_CARD_CUSTOM_AMOUNT:

      return action.payload;

    default:
      return state;
  }
};

export default giftCardCustomAmountReducer;
