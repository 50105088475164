// Action types constants

export const paymentActionTypes = {
  PAYMENT_LOADING: 'PAYMENT_LOADING',
  PAYMENT_LOADED: 'PAYMENT_LOADED',
  UPDATE_PAYMENT: 'UPDATE_PAYMENT',
  UPDATE_PAYMENT_STATE: 'UPDATE_PAYMENT_STATE',
  SET_INITIAL_PAYMENT_STATE: 'SET_INITIAL_PAYMENT_STATE',
};

export const paymentLoadingStart = () => ({
  type: paymentActionTypes.PAYMENT_LOADING,
});

export const paymentLoadingSuccess = () => ({
  type: paymentActionTypes.PAYMENT_LOADED,
});

export const updatePayment = payload => ({
  type: paymentActionTypes.UPDATE_PAYMENT,
  payload,
});

export const updatePaymentState = payload => ({
  type: paymentActionTypes.UPDATE_PAYMENT_STATE,
  payload,
});

export const setInitialPaymentState = payload => ({
  type: paymentActionTypes.SET_INITIAL_PAYMENT_STATE,
  payload,
});
